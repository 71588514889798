import React from 'react';
import { Col, Flex, Text, Wrapper } from '../../components/Ui';
import notif1 from '../../assets/images/notif1.png';
import notif2 from '../../assets/images/notif2.png';
import notif3 from '../../assets/images/notif3.png';
import styled from 'styled-components';
import { BsTrash } from 'react-icons/bs';
import { IoIosArrowDropright } from 'react-icons/io';
import { GoBook } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteSingleNews, getSingleNews, getAllNews } from '../../store/actions/news';
import { hexToRgba } from '../../utils/utils';
import { HailDimensions, LightbulbFlash, PoliceCar } from 'styled-icons/remix-fill';
const CustomFlex = styled(Flex)`
  //padding: 10px;
  border: ${({ theme }) =>
    '1px solid ' + theme.border_radius_generale_news || theme.colore_bordo_box};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  overflow: hidden;
  margin-bottom: 20px;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  ${respondTo.sm`
   border-top-right-radius: initial;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
     `};
`;
const Btn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color_icon_prd};
  font-size: 30px;
  /* position: absolute; */
  top: 10px;
  cursor: pointer;
  right: 10px;
  margin-left: auto;
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  /* position: absolute; */
  bottom: 10px;
  right: 10px;
  svg {
    font-size: 30px;
    margin-left: 5px;
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomCol = styled(Col)`
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 10px;
  background-color: ${({ bg, theme }) => !bg && theme.bg_box};
  ${respondTo.sm`
     border-top-right-radius: 10px;
     border-bottom-left-radius: 0;
  height: 300px;

  `};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notif_link {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;
const CustomText = styled(Text)`
  /* position: absolute; */
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    font-size: 27px;
    color: ${({ theme }) => theme.primary};
  }
`;

const Banner = styled.div``;

function Notification() {
  const { news } = useSelector(state => state.news);
  const code = useSelector(state => state.app.config.code);
  const colore_testo_generale = useSelector(
    state => state.app?.config?.design?.template_props?.colore_testo_generale,
  );
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});

  const dispatch = useDispatch();
  const readNews = id => {
    dispatch(getSingleNews(id)).then(() => dispatch(getAllNews()));
  };
  const deleteNotif = id => {
    dispatch(deleteSingleNews(id)).then(() => dispatch(getAllNews()));
  };

  return (
    <Wrapper>
      {code === 'CENTROGAMMAGO' && process.env.REACT_APP_SOURCE_IMG_NEWS && (
        <Banner>
          <img
            src={`${process.env.REACT_APP_SOURCE_IMG_NEWS}`}
            className='mb-20'
            style={{ width: '100%' }}
            alt=''
          />
        </Banner>
      )}
      {news?.filter(item => item.visible_in_list)?.length > 0 ? (
        news
          ?.filter(item => item.visible_in_list)
          .map(item => {
            return (
              <>
                <CustomFlex key={item.id} justify='center' align='stretch'>
                  <Col
                    width={50}
                    padding='0'
                    style={{
                      height: '300px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      overflow: 'hidden',
                    }}
                  >
                    <Image src={item.image_small} />
                  </Col>
                  <CustomCol
                    width={50}
                    bg={
                      item.bgcolor_dx && item.bgcolor_sx
                        ? `linear-gradient(to right, ${item.bgcolor_dx} 0%, ${item.bgcolor_sx} 100%)`
                        : null
                    }
                    // padding='0 100'
                    style={{ position: 'relative' /*display: 'flex', alignItems: 'center' */ }}
                  >
                    <div className='header'>
                      {!item.read && (
                        <CustomText as='p' bold capit>
                          <GoBook color={item.text_color_list} />{' '}
                          <Text
                            text_red
                            bold
                            size={18}
                            capit
                            color={item.text_color_list ?? colore_testo_generale}
                          >
                            da leggere
                          </Text>
                        </CustomText>
                      )}
                      <Btn white={!item.dark_text} onClick={() => deleteNotif(item.id)}>
                        <BsTrash color={item.text_color_list ?? colore_testo_generale} />
                      </Btn>
                    </div>

                    <Text text_box color={item.text_color_list ?? colore_testo_generale}>
                      {item.title}
                    </Text>
                    <div className='notif_link'>
                      <CustonLink
                        to={'/notifications/' + item.id}
                        onClick={() => readNews(item.id)}
                      >
                        <Text
                          capit
                          size={18}
                          text_red
                          bold
                          primary
                          style={{ display: 'flex', alignItems: 'center' }}
                          color={item.text_color_list ?? colore_testo_generale}
                        >
                          vai{' '}
                          <IoIosArrowDropright
                            color={item.text_color_list ?? colore_testo_generale}
                          />
                        </Text>
                      </CustonLink>
                    </div>
                  </CustomCol>
                </CustomFlex>
              </>
            );
          })
      ) : (
        <>
          <Text as='h1' size={40} className='mb-20' align='center'>
            {option?.menuSettings?.news?.label}
          </Text>
          <Text align='center' size={18}>
            Non ci sono novità da mostrare
          </Text>
        </>
      )}
    </Wrapper>
  );
}

export default Notification;
